import { categoryApi } from '@apis/categoryApi';
import Checkbox from '@components/Checkbox';
import { message } from '@components/Message';
import PopupSearchable from '@components/PopupSearchable';
import IconAdd3 from '@icons/IconAdd3';
import IconArrowDown2 from '@icons/IconArrowDown2';
import IconCancel12 from '@icons/IconCancel12';
import IconCancel16 from '@icons/IconCancel16';
import IconCheck from '@icons/IconCheck';
import { categoryService } from '@services/categoryService';
import { feedbackService } from '@services/feedbackService';
import { escapesValue, kmpSearch } from '@utils/common';
import constant from '@utils/constant';
import { getCategoriesSelector, getFeedbackCategoriesSelector } from '@utils/selectors';
import ContentLoading from '@views_admin/ContentLoading';
import { Dropdown } from 'antd';
import React, { memo, useState, useCallback, useRef, useMemo, useEffect } from 'react';

const _searchInOptionList = (optionList, searchValue) => {
   let results = [];
   optionList.forEach((option) => {
      if (kmpSearch(searchValue, escapesValue(option.name)) !== -1) {
         results = [...results, { ...option }];
      }
   });
   return results;
};

const SelectFeedbackCategory = memo(({ currentUid, value = {}, onChange, ...props }) => {
   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
   const [searchValue, setSearchValue] = useState('');
   const innerRef = useRef();
   const canceler = useRef();

   useEffect(() => {
      const loadData = async () => {
         canceler.current = await feedbackService.loadFeedbackCategories({
            page: 1,
            page_size: constant.defaultPagesize,
            order_by: 'updated_at',
            sort_type: 'DESC',
         });
      };
      loadData();
      return () => {
         if (canceler.current) {
            canceler.current.cancel();
         }
      };
   }, []);

   const handleSelectOption = (uid, name) => {
      onChange({
         uid,
         name,
      });
      setIsDropdownOpen(false);
   };

   const handleDropdownVisibleChange = useCallback((e) => {
      setIsDropdownOpen(e);
   }, []);

   const popupStyles = (() => {
      if (innerRef.current) {
         const rect = innerRef.current.getBoundingClientRect().width;
         return {
            width: `${rect}px`,
            maxWidth: `${rect}px`,
         };
      }
      return {};
   })();

   const clearOption = (e) => {
      e.stopPropagation();
      onChange(null);
   };

   const menu = (() => {
      return (
         <PopupSearchable
            className="pricing-types-dropdown"
            style={popupStyles}
            isOpen={isDropdownOpen}
            scrollContentClassName="pricing-types-dropdown__option-list"
            searchValue={searchValue}
            dataSelector={getFeedbackCategoriesSelector}
            setSearchValue={setSearchValue}
            funcSearchInOptionList={_searchInOptionList}
            funcFetchData={feedbackService.loadFeedbackCategories}
         >
            {({ optionList, loading }) => (
               <>
                  <ContentLoading isLoading={loading} size="small" />
                  {optionList.length === 0 && !loading && (
                     <div className="empty">No data</div>
                  )}
                  {optionList.map((option) => {
                     const isSelected = value?.uid === option.uid;
                     const disabled = loading || currentUid === option.uid;
                     return (
                        <div
                           className={`popup-searchable__item-option ${
                              isSelected ? 'selected' : ''
                           } ${disabled ? 'disabled' : ''}`}
                           key={option.uid}
                           onClick={() =>
                              !disabled && handleSelectOption(option.uid, option.name)
                           }
                        >
                           <span>{option.name}</span>
                           {isSelected && (
                              <i>
                                 <IconCheck />
                              </i>
                           )}
                        </div>
                     );
                  })}
               </>
            )}
         </PopupSearchable>
      );
   })();

   return (
      <Dropdown
         overlayClassName="common-dropdown size-small"
         visible={isDropdownOpen}
         overlay={menu}
         placement="bottomLeft"
         trigger="click"
         onVisibleChange={handleDropdownVisibleChange}
         overlayStyle={popupStyles}
      >
         <div className="fake-input-select" ref={innerRef}>
            {value?.uid ? (
               <span className="fake-input-select__single-selected">{value?.name}</span>
            ) : (
               <span className="placeholder">{props.placeholder}</span>
            )}
            {value?.uid && (
               <button className="chevron__clear" onClick={clearOption}>
                  <IconCancel16 />
               </button>
            )}
            <i className="chevron">
               <IconArrowDown2 />
            </i>
         </div>
      </Dropdown>
   );
});

export default SelectFeedbackCategory;
