import Input from '@components/Input';
import { message } from '@components/Message';
import IconAdd2 from '@icons/IconAdd2';
import { feedbackService } from '@services/feedbackService';
import { InputType } from '@utils/enums/InputType';
import { Role } from '@utils/enums/Role';
import { getProfileSelector } from '@utils/selectors';
import EditingDrawer from '@views_admin/components/EditingDrawer';
import SelectFeedbackCategory from '@views_admin/components/SelectFeedbackCategory';
import { Form } from 'antd';
import React, { memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

const formDefinitions = [
   {
      name: 'name',
      label: 'Name',
      placeholder: 'Category name',
      rules: [
         {
            required: true,
            message: 'Please input your Category name.',
         },
      ],
      isRequired: true,
   },
   {
      name: 'description',
      label: 'Description',
      type: InputType.TEXTAREA,
      placeholder: 'Description',
   },
   {
      name: 'parent',
      label: 'Parent',
      placeholder: 'Parent category',
      Component: SelectFeedbackCategory,
   },
   {
      name: 'require',
      label: 'Require',
      type: InputType.CHECKBOX,
      colorActive: '#2F80ED',
      options: [
         {
            label: 'Phone number',
            value: 'require_phone_number',
         },
         {
            label: 'Order ID',
            value: 'require_order_id',
         },
         {
            label: 'Product link',
            value: 'require_product_url',
         },
      ],
   },
];

const BtnAddFeedbackCategory = memo(({ onSuccess }) => {
   const [isOpen, setIsOpen] = useState(false);
   const { data: userProfile } = useSelector(getProfileSelector);
   const [form] = Form.useForm();
   const [submitting, setSubmitting] = useState(false);

   const onClose = useCallback(() => {
      setIsOpen(false);
   }, []);

   const onSubmit = useCallback(
      async (values) => {
         await setSubmitting(true);
         const body = {
            name: values.name || '',
            description: values.description || '',
            require_phone_number:
               values.require?.some((i) => i === 'require_phone_number') || false,
            require_order_id:
               values.require?.some((i) => i === 'require_order_id') || false,
            require_product_url:
               values.require?.some((i) => i === 'require_product_url') || false,
            parent_uid: values?.parent?.uid || '',
         };
         const response = await feedbackService.createFeedbackCategory(body);
         if (response.isSuccess) {
            onSuccess && onSuccess();
            message.success('Create new feedback category successfully.');
            onClose();
            form.resetFields();
         } else {
            message.error(response.message);
         }
         setSubmitting(false);
      },
      [onSuccess, form, onClose]
   );

   if (userProfile?.role_name !== Role.SYSTEM) return null;
   return (
      <>
         <button className="outline-btn btn-add-new-item" onClick={() => setIsOpen(true)}>
            <i>
               <IconAdd2 />
            </i>
            Add Feedback Category
         </button>

         <EditingDrawer
            title="Create a new Feedback Category"
            isOpen={isOpen}
            form={form}
            formDefinitions={formDefinitions}
            submitting={submitting}
            onClose={onClose}
            onSubmit={onSubmit}
         />
      </>
   );
});

export default BtnAddFeedbackCategory;
