import Input from '@components/Input';
import { message } from '@components/Message';
import IconAdd2 from '@icons/IconAdd2';
import { feedbackService } from '@services/feedbackService';
import { InputType } from '@utils/enums/InputType';
import { Role } from '@utils/enums/Role';
import { getProfileSelector } from '@utils/selectors';
import EditingDrawer from '@views_admin/components/EditingDrawer';
import SelectFeedbackCategory from '@views_admin/components/SelectFeedbackCategory';
import { Form, Tooltip } from 'antd';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _forIn from 'lodash/forIn';
import IconEdit from '@icons/IconEdit';

const formDefinitions = [
   {
      name: 'name',
      label: 'Name',
      placeholder: 'Category name',
      rules: [
         {
            required: true,
            message: 'Please input your Category name.',
         },
      ],
      isRequired: true,
   },
   {
      name: 'description',
      label: 'Description',
      type: InputType.TEXTAREA,
      placeholder: 'Description',
   },
   {
      name: 'parent',
      label: 'Parent',
      placeholder: 'Parent category',
      Component: SelectFeedbackCategory,
   },
   {
      name: 'require',
      label: 'Require',
      type: InputType.CHECKBOX,
      colorActive: '#2F80ED',
      options: [
         {
            label: 'Phone number',
            value: 'require_phone_number',
         },
         {
            label: 'Order ID',
            value: 'require_order_id',
         },
         {
            label: 'Product link',
            value: 'require_product_url',
         },
      ],
   },
];

const BtnEdit = memo(({ rowData, onSuccess }) => {
   const [isOpen, setIsOpen] = useState(false);
   const { data: userProfile } = useSelector(getProfileSelector);
   const [form] = Form.useForm();
   const [submitting, setSubmitting] = useState(false);

   const onClose = useCallback(() => {
      setIsOpen(false);
   }, []);

   const showDrawer = useCallback(() => {
      const valueObj = {};
      _forIn(rowData, (value, key) => {
         valueObj[key] = value;
      });
      form.setFieldsValue(valueObj);
      setIsOpen(true);
   }, [form, rowData]);

   useEffect(
      () => {
         let requiredList = [];
         if (rowData.require_phone_number) {
            requiredList.push('require_phone_number');
         }
         if (rowData.require_order_id) {
            requiredList.push('require_order_id');
         }
         if (rowData.require_product_url) {
            requiredList.push('require_product_url');
         }
         form.setFieldsValue({
            name: rowData?.name || '',
            description: rowData?.description || '',
            parent: {
               uid: rowData?.parent?.uid || '',
               name: rowData?.parent?.name || '',
            },
            require: requiredList,
         });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [rowData]
   );

   const onSubmit = useCallback(
      async (values) => {
         await setSubmitting(true);
         const body = {
            name: values.name || '',
            description: values.description || '',
            require_phone_number:
               values.require?.some((i) => i === 'require_phone_number') || false,
            require_order_id:
               values.require?.some((i) => i === 'require_order_id') || false,
            require_product_url:
               values.require?.some((i) => i === 'require_product_url') || false,
            parent_uid: values?.parent?.uid || '',
         };
         const response = await feedbackService.updateFeedbackCategory(rowData.uid, body);
         if (response.isSuccess) {
            onSuccess && onSuccess();
            message.success(`You successfully updated feedback category data`);
            onClose();
            form.resetFields();
         } else {
            message.error(response.message);
         }
         setSubmitting(false);
      },
      [onSuccess, rowData.uid, form, onClose]
   );

   if (userProfile?.role_name !== Role.SYSTEM) return null;
   return (
      <>
         <Tooltip placement="bottom" title="Edit" color="black">
            <button className="button-icon action-normal" onClick={showDrawer}>
               <IconEdit />
            </button>
         </Tooltip>

         <EditingDrawer
            title="Edit Feedback Category"
            isOpen={isOpen}
            form={form}
            formDefinitions={formDefinitions}
            submitting={submitting}
            currentUid={rowData.uid}
            onClose={onClose}
            onSubmit={onSubmit}
         />
      </>
   );
});

export default BtnEdit;
