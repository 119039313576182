import { SortType } from '@apis/enums/SortType';
import { createCancelToken } from '@apis/ServiceController';
import SEO from '@components/SEO';
import { feedbackService } from '@services/feedbackService';
import { convertToDate } from '@utils/common';
import constant from '@utils/constant';
import { Link } from 'gatsby';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import BtnAddFeedbackCategory from '@views_admin/feedback/BtnAddFeedbackCategory';
import ContentLoading from '@views_admin/ContentLoading';
import Search from '@views_admin/layout/Search';
import Sort from '@views_admin/components/Sort';
import CommonTable from '@views_admin/components/CommonTable';
import BtnEdit from '@views_admin/feedback/BtnEdit';
import BtnDelete from '@views_admin/feedback/BtnDelete';

const sortOptions = [
   {
      label: 'Last modified',
      value: 'updated_at',
   },
   {
      label: 'Date created',
      value: 'created_at',
   },
   {
      label: 'Name',
      value: 'name',
   },
];

const FeedbackCategories = memo(() => {
   const [searchText, setSearchText] = useState('');
   const [filter, setFilter] = useState({
      page: 1,
      page_size: constant.defaultPagesize,
      order_by: 'updated_at',
      sort_type: SortType.DESC,
   });
   const [data, setData] = useState({
      content: [],
      totalRows: 0,
   });
   const [loading, setLoading] = useState(false);
   const cancelToken = useRef();

   useEffect(() => {
      const loadData = async () => {
         if (cancelToken.current) {
            cancelToken.current.cancel();
         }
         await setLoading(true);
         cancelToken.current = createCancelToken();
         const res = await feedbackService.getFeedbackCategoryMatrix(
            { ...filter, search: searchText.trim() },
            cancelToken.current?.token
         );
         if (res.isSuccess) {
            const { content, totalRows } = res.data;
            setData((prevData) => ({
               ...prevData,
               content: content || [],
               totalRows: totalRows,
            }));
         }
         setSearchText(searchText.trim());
         setLoading(false);
      };

      loadData();

      return () => {
         if (cancelToken.current) {
            cancelToken.current.cancel();
         }
      };
   }, [filter, searchText]);

   const onTableChange = useCallback(({ pagination, sorter }) => {
      setFilter((prevFilter) => ({
         ...prevFilter,
         page: pagination?.page || prevFilter.page,
         page_size: pagination?.page_size || prevFilter.page_size,
      }));
   }, []);

   const reload = useCallback((resetPageNo) => {
      setFilter((prevFilter) => ({
         ...prevFilter,
         page: resetPageNo ? 1 : prevFilter.page,
      }));
   }, []);

   const columns = useMemo(() => {
      return [
         {
            title: 'Category name',
            dataIndex: 'name',
         },
         {
            title: 'Created date',
            dataIndex: 'created_at',
            render: (text) => <span>{text ? convertToDate(text) : ''}</span>,
         },
         {
            title: 'Require',
            dataIndex: 'uid',
            render: (_, record) => {
               let requiredList = [];
               if (record.require_order_id) {
                  requiredList.push('Order ID');
               }
               if (record.require_phone_number) {
                  requiredList.push('Phone number');
               }
               if (record.require_product_url) {
                  requiredList.push('Product link');
               }
               console.log(requiredList);
               const requiredText = requiredList.join(', ');
               return requiredText ? requiredText : <span className="nothing">--</span>;
            },
         },
         {
            title: 'Parent',
            dataIndex: 'parent',
            render: (text, record) => (
               <p className="description">
                  {text?.name ? text.name : <span className="nothing">--</span>}
               </p>
            ),
         },
         {
            title: 'Feedback counts',
            dataIndex: 'feedback_amount',
            render: (text, record) => (
               <span>{text || <span className="nothing">0</span>}</span>
            ),
         },
         {
            title: 'Action',
            key: 'action',
            width: 116,
            render: (text, record) => (
               <div className="admin-col-action">
                  <BtnEdit rowData={record} onSuccess={reload} />
                  {!record.used && (
                     <BtnDelete uid={record.uid} name={record.name} onSuccess={reload} />
                  )}
               </div>
            ),
         },
      ];
   }, [reload]);

   const handleSort = useCallback((orderBy, sortType) => {
      setFilter((prevFilter) => ({
         ...prevFilter,
         sort_type: sortType,
         order_by: orderBy,
      }));
   }, []);

   const handleAddFeeSuccess = useCallback(() => {
      setFilter((prevFilter) => ({
         ...prevFilter,
      }));
   }, []);

   return (
      <>
         <SEO title={'Feedback - Admin'} />
         <div className="content-head">
            <div className="content-head__left">
               <div className="admin-shipment__tabs">
                  <Link className={`tab-item`} to={`${constant.ROUTE_ADMIN_FEEDBACK}`}>
                     Feedback
                  </Link>
                  <div className={`tab-item active`}>Feedback Categories</div>
               </div>
            </div>
            <div className="content-head__right">
               <BtnAddFeedbackCategory onSuccess={handleAddFeeSuccess} />
            </div>
         </div>
         <div className="content-body admin-products__body">
            <div className="admin-table">
               <ContentLoading isLoading={loading} />
               <div className="admin-table__header">
                  <div className="admin-table__header--left">
                     <Search placeholder="Search..." onSearch={(v) => setSearchText(v)} />
                  </div>
                  <div className="admin-table__header--right">
                     <Sort
                        orderBy={filter.order_by}
                        sortType={filter.sort_type}
                        options={sortOptions}
                        onChange={handleSort}
                     />
                  </div>
               </div>
               <CommonTable
                  rowKey="uid"
                  unit="Feedbacks categories"
                  dataSource={data.content}
                  columns={columns}
                  page={filter.page}
                  pageSize={filter.page_size}
                  totalRows={data.totalRows}
                  onChange={onTableChange}
               />
            </div>
         </div>
      </>
   );
});

export default FeedbackCategories;
