import React, { Fragment, memo } from 'react';
import { Drawer, Form, Row, Col } from 'antd';
import Input from '../../components/Common/Input';

const EditingDrawer = memo(
   ({ isOpen, title, form, submitting, currentUid, formDefinitions, ...ownProps }) => {
      return (
         <Drawer
            className="common-drawer drawer-small-editing admin-categories-drawer"
            title={
               <div className="common-drawer__header">
                  <label>{title}</label>
                  <div className="common-drawer__header--actions">
                     <button className="cancel" onClick={ownProps.onClose}>
                        Close
                     </button>
                     <button
                        className="submit"
                        disabled={submitting}
                        onClick={form.submit}
                     >
                        {submitting ? 'Saving' : 'Save'}
                     </button>
                  </div>
               </div>
            }
            width={454}
            onClose={ownProps.onClose}
            visible={isOpen}
            maskClosable={true}
            closeIcon={null}
            destroyOnClose={true}
         >
            <Form form={form} className="submit-form" onFinish={ownProps.onSubmit}>
               <Row gutter={8}>
                  {formDefinitions.map(({ label, ...item }) => {
                     return (
                        <Fragment key={item.name}>
                           <Col lg={5} md={5} sm={24} xs={24}>
                              <label className="submit-form__field-title">
                                 {label}{' '}
                                 {item.isRequired && (
                                    <span style={{ color: '#FF5F73' }}> *</span>
                                 )}
                              </label>
                           </Col>
                           <Col lg={19} md={19} sm={24} xs={24}>
                              <Form.Item
                                 name={item.name}
                                 dependencies={item.dependencies}
                                 rules={item.rules || []}
                              >
                                 {item.Component ? (
                                    <item.Component
                                       {...item}
                                       inputClassName="h40"
                                       currentUid={currentUid}
                                    />
                                 ) : (
                                    <Input
                                       {...item}
                                       inputClassName="h40"
                                       type={item.type}
                                       placeholder={item.placeholder}
                                    />
                                 )}
                              </Form.Item>
                           </Col>
                        </Fragment>
                     );
                  })}
               </Row>
            </Form>
         </Drawer>
      );
   }
);

export default EditingDrawer;
